import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Icon from '../../Icon';
import Spacer from '../../Spacer';

import FeatureExplanation from '../FeatureExplanation';
import { media } from '../../../utils/MediaResponsive';
import jsonData from '../../../content/essay-corrections/features';

const { audioExplanation } = jsonData;

const Wrapper = styled.article`
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-evenly;
  align-items: center;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const ImageContainer = styled.div`
  width: 50%;

  ${media.tablet`
    width: 90vw;
  `}
`;

const Feature = styled(FeatureExplanation)`
  max-width: 400px;
  ${media.tablet`
    width: 80vw;
    max-width: unset;
  `}
`;

const Owl = styled(Icon).attrs({ type: 'Owl' })`
  font-size: 120px;

  ${media.tablet`
    font-size: 80px;
  `}
`;

const FeatureAudioExplanation = () => (
  <StaticQuery
    query={graphql`
      query {
        audioExplanation: file(relativePath: { eq: "essay-correction/audio-explanation.png" }) {
          childImageSharp {
            fluid(maxWidth: 580) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Feature
          title={audioExplanation.title}
          description={audioExplanation.description}
          iconType={audioExplanation.iconType}
          alignment="right"
        />
        <Spacer tablet={40} />
        <ImageContainer>
          <Owl />
          <Spacer height={10} />
          <Img fluid={data.audioExplanation.childImageSharp.fluid} />
        </ImageContainer>
      </Wrapper>
    )}
  />
);

export default FeatureAudioExplanation;
