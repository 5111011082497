import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { Pagination } from 'swiper/dist/js/swiper.esm';

import Swiper from 'react-id-swiper';
import FeatureExplanation from './FeatureExplanation';
import Spacer from '../Spacer';
import Person from '../Person';
import jsonData from '../../content/essay-corrections/good-writers';
import { findImageByName } from '../../utils';
import Info from './Info';
import 'swiper/dist/css/swiper.css';

const Wrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.bgHeader};
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 0 10vw;
  }

  .good-writer-bullets {
    padding-top: 30px;
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
      background: white;
      mix-blend-mode: normal;
      opacity: 0.23;

      &-active {
        background: ${p => p.theme.brand};
        opacity: 1;
      }
    }
  }
`;

const Feature = styled(FeatureExplanation)`
  max-width: 850px;
`;

const InfoContainer = styled.div`
  position: absolute;
  bottom: calc(-347px / 2);
  left: 0;
  padding: 0 10%;

  @media screen and (max-width: 1024px) {
    bottom: -50px;
  }
`;

const SwiperContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 1280px) {
    max-width: 850px;
    padding-bottom: 220px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 80vw;
    padding-bottom: 520px;
  }

  @media screen and (max-width: 992px) {
    padding-bottom: 580px;
  }

  @media screen and (max-width: 565px) {
    padding-bottom: 600px;
  }

  @media screen and (max-width: 530px) {
    padding-bottom: 670px;
  }

  @media screen and (max-width: 455px) {
    padding-bottom: 700px;
  }

  @media screen and (max-width: 405px) {
    padding-bottom: 730px;
  }

  @media screen and (max-width: 380px) {
    padding-bottom: 760px;
  }

  @media screen and (max-width: 365px) {
    padding-bottom: 790px;
  }

  @media screen and (max-width: 335px) {
    padding-bottom: 850px;
  }

  @media screen and (max-width: 325px) {
    padding-bottom: 880px;
  }
`;

const params = {
  slidesPerView: 4,
  freeMode: true,
  modules: [Pagination],
  pagination: {
    el: '.good-writer-bullets',
    type: 'bullets',
    clickable: true,
  },
  spaceBetween: 30,
  breakpoints: {
    992: {
      slidesPerView: 3.5,
    },
    892: {
      slidesPerView: 3,
    },
    792: {
      slidesPerView: 2.5,
    },
    550: {
      slidesPerView: 2,
    },
    450: {
      slidesPerView: 1.5,
    },
    350: {
      slidesPerView: 1,
    },
  },
};

const GoodWriters = () => (
  <StaticQuery
    query={graphql`
      query {
        goodWriters: allFile(filter: { relativeDirectory: { eq: "essay-correction/good-writers" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Spacer desktop={62} mobile={40} />
        <Feature
          invert
          alignment="center"
          iconType={jsonData.iconType}
          title={jsonData.title}
          description={jsonData.description}
        />
        <Spacer desktop={72} tablet={30} />
        <SwiperContainer>
          <Swiper {...params}>
            {jsonData &&
              jsonData.writers.map(writer => (
                <div style={{ display: 'inline-block' }} key={writer.id}>
                  <Person
                    fullName={writer.fullName}
                    profilePhoto={findImageByName(writer.photoName, data, 'goodWriters', 'fluid')}
                    info={writer.score}
                  />
                </div>
              ))}
          </Swiper>
        </SwiperContainer>
        <InfoContainer>
          <Info />
        </InfoContainer>
      </Wrapper>
    )}
  />
);

export default GoodWriters;
