import React from 'react';

import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Container from '../components/Container';

import { Hero, Features, GoodWriters, LoginCTA, StudentSaid } from '../components/EssayCorrections';

const IndexPage = () => (
  <MainLayout>
    <SEO title="Home" keywords={[`dolonline`, `dolenglish`, `ielts`, `essay corrections`]} />
    <Container>
      <Hero />
    </Container>
    <Container bgc="#f9f9f9">
      <Features />
    </Container>
    <Container bgc="#2D3942">
      <GoodWriters />
    </Container>
    <Container bgc="#FBFBFB">
      <StudentSaid />
    </Container>
    <Container bgc="#D22222">
      <LoginCTA />
    </Container>
  </MainLayout>
);

export default IndexPage;
