import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Swiper from 'react-id-swiper';
import { Pagination } from 'swiper/dist/js/swiper.esm';
import 'swiper/dist/css/swiper.css';

import { findImageByName } from '../../utils';
import Spacer from '../Spacer';
import jsonData from '../../content/essay-corrections/student-said';
import Quote from './Quote';
import Person from '../Person';
import RedLine from './RedLine';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .student-said-bullets {
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
      background: #dadada;
      mix-blend-mode: normal;
      opacity: 0.23;

      &-active {
        background: ${p => p.theme.bgHeader};
        opacity: 1;
      }
    }
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 80vw;
    margin: 0 auto;
  }
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const params = {
  slidesPerView: 1,
  freeMode: false,
  modules: [Pagination],
  pagination: {
    el: '.student-said-bullets',
    type: 'bullets',
    clickable: true,
  },
};

const personOptions = {
  imgStyle: {
    border: '6px solid rgba(142, 142, 142, 0.146994)',
    width: 56,
    height: 56,
  },
  fullNameStyle: {
    color: 'black',
    fontWeight: 700,
    fontSize: 17,
    fontHeight: 20,
  },
  infoStyle: {
    fontSize: 12,
    fontHeight: 14,
    color: 'black',
    opacity: 0.5,
  },
  topDistance: 10,
  bottomDistance: 5,
};

const StudentSaid = () => (
  <StaticQuery
    query={graphql`
      query {
        students: allFile(filter: { relativeDirectory: { eq: "essay-correction/student-said" } }) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Spacer desktop={250} tablet={130} mobile={125} />
        <Title>{jsonData.title}</Title>
        <Spacer desktop={25} tablet={25} mobile={15} />
        <RedLine />
        <Spacer desktop={22} tablet={22} mobile={12} />
        <SwiperContainer>
          <Swiper {...params}>
            {jsonData &&
              jsonData.quotes.map(quote => (
                <div key={quote.id} style={{ width: '100%', padding: '40px 0' }}>
                  <QuoteContainer>
                    <Quote content={quote.content} />
                    <Spacer desktop={32} tablet={32} mobile={32} />
                    <Person
                      options={personOptions}
                      fullName={quote.fullName}
                      profilePhoto={findImageByName(quote.photoName, data, 'students', 'fluid')}
                      info={quote.info}
                    />
                  </QuoteContainer>
                </div>
              ))}
          </Swiper>
        </SwiperContainer>
        <Spacer desktop={50} tablet={50} mobile={50} />
      </Wrapper>
    )}
  />
);

export default StudentSaid;
