import React from 'react';
import styled from 'styled-components';
import demoGif from '../../../images/essay-correction/correct-mistake-demo.gif';

import FeatureExplanation from '../FeatureExplanation';
import { media } from '../../../utils/MediaResponsive';
import Spacer from '../../Spacer';
import jsonData from '../../../content/essay-corrections/features';

const { correctMistake } = jsonData;

const Wrapper = styled.article`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const ImageContainer = styled.div`
  width: 50%;

  ${media.tablet`
    width: 90vw;
  `}
`;

const Feature = styled(FeatureExplanation)`
  max-width: 400px;

  ${media.tablet`
    width: 80vw;
    max-width: unset;
  `}
`;

const FeatureCorrectMistake = () => (
  <Wrapper>
    <Feature title={correctMistake.title} description={correctMistake.description} iconType={correctMistake.iconType} />
    <Spacer tablet={30} />
    <ImageContainer>
      <img src={demoGif} alt="Correct Mistake" />
    </ImageContainer>
  </Wrapper>
);

export default FeatureCorrectMistake;
