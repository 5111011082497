import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { darken } from 'polished';

import Spacer from '../Spacer';
import Button from '../Button';
import login from '../../content/essay-corrections/loginCta';

const Wrapper = styled.div`
  background-color: ${p => p.theme.brand};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
`;

const LoginBtn = styled(Button)`
  color: ${p => p.theme.brand};
  text-transform: uppercase;

  &:hover {
    color: ${p => darken(0.1, p.theme.brand)};
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.4px;
  color: white;
`;

const LoginCTA = () => (
  <Wrapper>
    <Spacer desktop={56} />
    <Title>{login.title}</Title>
    <Spacer desktop={38} />
    <LoginBtn backgroundColor="#fff" onClick={() => navigate(login.href)}>
      {login.label}
    </LoginBtn>
    <Spacer desktop={56} />
  </Wrapper>
);

export default LoginCTA;
