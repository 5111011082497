import styled from 'styled-components';

const RedLine = styled.div`
  background: ${p => p.theme.brand};
  border-radius: 2px;
  width: 33px;
  height: 4px;
`;

export default RedLine;
