import styled, { css } from 'styled-components';
import { sizesResponsive } from '../../utils/MediaResponsive';

const Spacer = styled.div`
  height: ${p => p.height}px;

  ${p =>
    p.desktop &&
    css`
      @media screen and (min-width: ${sizesResponsive.tablet + 1}px) {
        height: ${p.desktop}px;
      }

      @media screen and (max-width: ${sizesResponsive.tablet}px) {
        height: ${p.tablet || p.desktop}px;
      }

      @media screen and (max-width: 768px) {
        height: ${p.mobile || p.tablet || p.desktop}px;
      }
    `}
      
  ${p =>
    p.tablet &&
    css`
      @media screen and (max-width: ${sizesResponsive.tablet}px) {
        height: ${p.tablet}px;
      }

      @media screen and (max-width: 768px) {
        height: ${p.mobile || p.tablet}px;
      }
    `}
      
  ${p =>
    p.mobile &&
    css`
      @media screen and (max-width: 768px) {
        height: ${p.mobile}px;
      }
    `}
}
  
  
`;

export default Spacer;
