import React from 'react';
import styled from 'styled-components';

import FeatureCorrectMistake from './Features/FeatureCorrectMistake';
import FeatureAudioExplanation from './Features/FeatureAudioExplanation';
import Spacer from '../Spacer';
import BackgroundImage from '../../images/pattern-bgi.png';
import { media } from '../../utils/MediaResponsive';

const Wrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  background: #f9f9f9 url(${BackgroundImage}) center right no-repeat;

  ${media.tablet`
    background-image: unset;
  `}
`;

const Features = () => (
  <Wrapper>
    <Spacer desktop={80} tablet={40} />
    <FeatureCorrectMistake />
    <Spacer desktop={80} tablet={40} />
    <FeatureAudioExplanation />
  </Wrapper>
);

export default Features;
