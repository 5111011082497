import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Spacer from '../Spacer';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  p {
    margin: 0;
  }
`;

const ProfileWrapper = styled.div`
  width: 168px;
  height: 168px;
  border-radius: 50%;
  overflow: hidden;
`;

const FullName = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2125px;
  color: white;
`;

const Info = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.15px;
  color: white;
  mix-blend-mode: normal;
  opacity: 0.5;
`;

const Person = ({ profilePhoto, fullName, info, options }) => (
  <Wrapper>
    <ProfileWrapper style={options.imgStyle}>
      <Img fluid={profilePhoto} />
    </ProfileWrapper>
    <Spacer desktop={options.topDistance} mobile={options.topDistance / 2} />
    <FullName style={options.fullNameStyle}>{fullName}</FullName>
    <Spacer desktop={options.bottomDistance} />
    <Info style={options.infoStyle}>{info}</Info>
  </Wrapper>
);

Person.propTypes = {
  profilePhoto: PropTypes.shape({}).isRequired,
  fullName: PropTypes.string.isRequired,
  info: PropTypes.string,
  options: PropTypes.shape({
    imgStyle: PropTypes.shape({}),
    fullNameStyle: PropTypes.shape({}),
    infoStyle: PropTypes.shape({}),
    topDistance: PropTypes.number,
    bottomDistance: PropTypes.number,
  }),
};

Person.defaultProps = {
  info: '',
  options: {
    topDistance: 45,
    bottomDistance: 8,
    imgStyle: {},
    infoStyle: {},
    fullNameStyle: {},
  },
};

export default Person;
