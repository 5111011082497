import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Spacer from '../Spacer';
import { media } from '../../utils/MediaResponsive';
import RedLine from './RedLine';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: ${p => p.align};
  text-align: ${p => p.textAlign};

  p {
    margin: 0;
  }

  ${p =>
    p.invert &&
    css`
      p {
        color: white;
      }
    `}
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};

  ${media.tablet`
    font-size: 24px;
  `}
`;

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #4f4f4f;

  ${media.tablet`
    font-size: 14px;
  `}
`;

const FeatureIcon = styled(Icon)`
  font-size: 50px;
  display: inline-block;
`;

const alignmentMap = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const FeatureExplanation = ({ iconType, title, description, alignment, invert, ...props }) => (
  <Wrapper {...props} align={alignmentMap[alignment]} textAlign={alignment} invert={invert}>
    <span>
      <FeatureIcon stype={{ fontSize: 60 }} type={iconType} />
    </span>
    <Spacer height={34} />
    {title && <Title>{title}</Title>}
    <Spacer height={16} />
    {title && <RedLine />}
    <Spacer height={27} />
    {description && <Description>{description}</Description>}
  </Wrapper>
);

FeatureExplanation.propTypes = {
  iconType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'right', 'center']),
  invert: PropTypes.bool,
};

FeatureExplanation.defaultProps = {
  iconType: '',
  title: '',
  description: '',
  alignment: 'left',
  invert: false,
};

export default FeatureExplanation;
