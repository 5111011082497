import React from 'react';
import styled from 'styled-components';

import Spacer from '../Spacer';
import FeatureExplanation from './FeatureExplanation';
import jsonData from '../../content/essay-corrections/info';

const { service, question } = jsonData;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  box-shadow: 0 14px 24px rgba(54, 51, 74, 0.14);
  border-radius: 16px;
  padding: 28px 28px 0;

  @media screen and (max-width: 1024px) {
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const Feature = styled(FeatureExplanation)`
  max-width: 35%;
  margin-bottom: 28px;

  @media screen and (max-width: 1024px) {
    max-width: 80%;
  }
`;

const Separator = styled.div`
  padding-top: 250px;
  width: 1px;
  border-right: 1px solid #e2e2e2;
  align-self: flex-end;

  @media screen and (max-width: 1024px) {
    flex-flow: column nowrap;
    padding-top: 0;
    height: 1px;
    align-self: auto;
    border-bottom: 1px solid #e2e2e2;
    padding-left: 250px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 40vw;
  }
`;

const Info = () => (
  <Wrapper>
    <Feature
      alignment={service.alignment}
      title={service.title}
      description={service.description}
      iconType={service.iconType}
    />
    <Separator />
    <Spacer tablet={27} />
    <Feature
      alignment={question.alignment}
      title={question.title}
      description={question.description}
      iconType={question.iconType}
    />
  </Wrapper>
);

export default Info;
