import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { media } from '../../utils/MediaResponsive';

const Wrapper = styled.div`
  background-color: white;
  background-size: cover;
  max-width: 600px;
  padding: 70px;
  position: relative;
  box-shadow: 0 14px 24px rgba(54, 51, 74, 0.14);
  border-radius: 16px;

  ${media.tablet`
    padding: 40px;
  `}

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 12px);
    bottom: -10px;
    border-top: 12px solid white;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }
`;

const QuoteContent = styled.p`
  text-align: center;
  display: inline-block;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${p => p.theme.bgHeader};

  ${media.tablet`
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
   `}
`;

const QuoteIcon = styled(Icon)`
  position: absolute;
  top: -50px;
  left: 84px;
  font-size: 120px;

  ${media.tablet`
    font-size: 60px;
    top: -28px;
  `}

  ${media.phone`
    font-size: 40px;
    top: -18px;
    left: 20px;  
  `}
`;

const Quote = ({ content }) => (
  <Wrapper>
    <QuoteIcon type="Quote" />
    <QuoteContent>{content}</QuoteContent>
  </Wrapper>
);

Quote.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Quote;
